exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activities-js": () => import("./../../../src/pages/activities.js" /* webpackChunkName: "component---src-pages-activities-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-donate-pay-js": () => import("./../../../src/pages/donate/pay.js" /* webpackChunkName: "component---src-pages-donate-pay-js" */),
  "component---src-pages-donate-pay-single-js": () => import("./../../../src/pages/donate/pay-single.js" /* webpackChunkName: "component---src-pages-donate-pay-single-js" */),
  "component---src-pages-donation-thanks-js": () => import("./../../../src/pages/donation-thanks.js" /* webpackChunkName: "component---src-pages-donation-thanks-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-activity-page-js": () => import("./../../../src/templates/activity-page.js" /* webpackChunkName: "component---src-templates-activity-page-js" */),
  "component---src-templates-blog-single-js": () => import("./../../../src/templates/blog-single.js" /* webpackChunkName: "component---src-templates-blog-single-js" */),
  "component---src-templates-default-page-js": () => import("./../../../src/templates/default-page.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-group-page-js": () => import("./../../../src/templates/group-page.js" /* webpackChunkName: "component---src-templates-group-page-js" */)
}

